.container {
  width: 100%;
  max-width: 1140px;
  padding: 100px 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.text {
  font-family: "Roboto Mono", Sans-serif;
  font-weight: 600;
  font-size: 32px;
  text-align: center;
}

.platforms {
  background-color: #292929;
  padding: 60px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  align-items: center;
  overflow-x: hidden;
}

.title {
  width: fit-content;
  color: #fff;
}

.icons {
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
}

.icons div {
  width: 160px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.icons img {
  height: 100px;
}

@media (max-width: 1140px) {
  .container {
    padding: 90px 30px 50px;
  }

  .platforms {
    flex-direction: column;
    padding: 30px;
  }

  .title {
    text-align: center;
  }

  .icons {
    gap: 30px;
  }

  .icons img {
    height: 75px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 80px 20px 40px;
  }

  .icons {
    flex-wrap: wrap;
  }

  .platforms {
    padding: 20px;
  }
}
