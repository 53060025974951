.root {
  padding: 100px 150px;
  background-image: url("./assets/bg.jpg");
}

.title {
  color: #000;
  font-size: 80px;
  font-weight: 600;
  margin-bottom: 50px;
  font-family: "Roboto Slab", Sans-serif;
}

.root h6 {
  font-family: "Roboto Slab", sans-serif;
  font-size: 17px;
  font-weight: 700;
}

.root p {
  font-family: "Roboto", sans-serif;
  color: #7a7a7a;
  font-size: 17px;
  text-align: justify;
}

.root strong {
  font-family: "Roboto Slab", Sans-serif;
  font-weight: 400;
  color: #000;
}

.root section {
  margin-bottom: 30px;
}

.root ul {
  padding: 40px 50px;
}

.root li {
  list-style: disc;
  font-family: "Roboto Slab", sans-serif;
  font-size: 17px;
}

@media (max-width: 1160px) {
  .root {
    padding: 70px 50px;
  }

  .title {
    font-size: 50px;
  }
}

@media (max-width: 768px) {
  .root {
    padding: 50px 20px;
  }

  .title {
    font-size: 40px;
  }
}
