.root {
  height: 85px;
  background-color: #a5a5a547;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--header-z-index);
  display: flex;
  justify-content: space-between;
  padding: 0 150px;
  align-items: center;
  backdrop-filter: blur(10px);
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  font-family: "Roboto Mono", sans-serif;
}

.link {
  font-size: 15px;
  font-weight: 600;
  padding: 18px 15px 15px;
  color: #fff;
  letter-spacing: 1px;
  border-bottom: 3px solid transparent;
  transition: border-bottom-color 0.3s, color 0.3s;
}

.link:hover {
  color: var(--main-color);
  border-bottom-color: var(--main-color);
}

.menuButton {
  display: none;
  width: 22px;
  height: 20px;
  flex-direction: column;
  justify-content: space-between;
}

.menuButton div {
  width: 100%;
  height: 3px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.menuButtonOpened div:nth-child(1) {
  transform: rotate(-45deg) translate(-6px, 6px);
}

.menuButtonOpened div:nth-child(2) {
  opacity: 0;
}

.menuButtonOpened div:nth-child(3) {
  transform: rotate(45deg) translate(-6px, -6px);
}

@media (max-width: 1140px) {
  .root {
    padding: 0 70px;
  }
}

@media (max-width: 768px) {
  .root {
    padding: 0 30px;
    height: 70px;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
    height: fit-content;
  }

  .buttonContainer {
    height: 75px;
    display: flex;
    padding: 0 20px;
    align-items: center;
  }

  .menuButton {
    display: flex;
  }

  .navigation {
    display: none;
    flex-direction: column;
    align-items: stretch;
  }

  .link {
    text-align: center;
  }

  .opened {
    display: flex;
  }
}
