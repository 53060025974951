:root {
  --main-color: #5fa8f2;
  --accent-color: #a3ecd3;

  --header-z-index: 1;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

svg,
img {
  display: block;
}

a {
  text-decoration: none;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

li {
  list-style: none;
}

h1 {
  font-size: 80px;
  line-height: 1.3em;
  text-align: center;
  font-family: "Roboto Slab", Sans-serif;
  font-weight: 900;
  letter-spacing: 1.6px;
  color: #fff;
}

h2 {
  font-size: 70px;
  font-weight: 600;
  text-align: center;
  line-height: 1.3em;
  font-family: "Roboto Mono", Sans-serif;
  color: #fff;
}

h3 {
  font-family: "Roboto Mono", Sans-serif;
  font-size: 50px;
  font-weight: 600;
  line-height: 1.3em;
}

h4 {
  font-family: "Roboto Mono", Sans-serif;
  font-weight: 600;
  font-size: 25px;
  letter-spacing: 1.5px;
  color: #fff;
}

p {
  font-size: 15px;
  line-height: 1.65em;
  font-weight: 400;
}

@media (max-width: 1160px) {
  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 35px;
  }

  h3 {
    font-size: 38px;
  }
}
