.root {
  background-image: url("./assets/bg.jpg");
}

.container {
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
}

.link {
  color: #000;
  text-decoration: underline;
}

@media (max-width: 1160px) {
  .container {
    padding: 90px 30px 60px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 80px 20px 40px;
  }
}
