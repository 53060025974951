.root {
  display: block;
  padding: 12px 20px;
  border-radius: 3px;
  border: 3px solid #fff;
  color: #fff;
  font-family: "Roboto Mono", Sans-serif;
  font-size: 15px;
  transition: transform 0.3s, background-color 0.3s;
}

.root:hover {
  color: #ff6900;
  background-color: #fff;
}

.root:not(.withoutScale):hover {
  transform: scale(1.1);
}

.mainColor {
  border-color: var(--main-color);
  color: var(--main-color);
}

.mainColor:hover {
  background-color: var(--main-color);
  color: #fff;
}

.accentColor {
  border-color: var(--accent-color);
  color: var(--accent-color);
}

.accentColor:hover {
  background-color: var(--accent-color);
}

.accentInvertedColor {
  border-color: var(--accent-color);
  color: #000;
  background-color: var(--accent-color);
}

.big {
  padding: 20px 30px;
}
