.root {
  padding: 100px 80px;
  display: flex;
  align-items: center;
  gap: 30px;
  overflow-x: hidden;
}

.root > * {
  width: 50%;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
}

.title {
  color: #000;
}

.buttons {
  display: flex;
  gap: 20px;
}

.right img {
  max-width: 650px;
  margin: 0 auto;
  width: 100%;
}

@media (max-width: 1140px) {
  .root {
    padding: 90px 30px 50px;
  }
}

@media (max-width: 768px) {
  .root {
    padding: 80px 20px 40px;
    flex-direction: column-reverse;
  }

  .root > div {
    width: 100%;
  }

  .left {
    gap: 30px;
  }

  .buttons {
    align-self: stretch;
    justify-content: space-between;
  }

  .right img {
    max-width: 450px;
  }
}
