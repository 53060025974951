.root {
  background-image: linear-gradient(160deg, #000000 15%, #78c1f1 100%);
  overflow: hidden;
}

.top {
  padding: 100px 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.subtitle {
  max-width: 620px;
  text-align: center;
  color: var(--accent-color);
}

.bottom {
  position: relative;
}

.bottomContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  gap: 10px;
}

.phone {
  padding: 30px 0;
}

.phone img {
  width: 100%;
  margin: 0 auto;
  max-width: 360px;
}

.bg {
  background-image: url("./assets/bg.jpg");
  position: absolute;
  top: 30%;
  right: 0;
  bottom: 0;
  left: 0;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.bottom ul {
  height: 70%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
}

.left ul {
  align-items: flex-end;
}

.left li {
  text-align: end;
}

.bottom li {
  font-family: "Roboto Mono", Sans-serif;
  font-weight: 600;
  font-size: 32px;
}

@media (max-width: 1140px) {
  .top {
    padding: 90px 20px 40px;
  }

  .bottom li {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .top {
    padding: 80px 20px 20px;
  }

  .bottomContainer {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .phone {
    order: 1;
  }

  .left {
    order: 2;
  }

  .right {
    order: 3;
  }

  .phone {
    padding: 0 20px;
  }

  .bottom ul {
    height: 100%;
    gap: 20px;
  }

  .left ul {
    align-items: flex-start;
    padding: 20px 20px 0;
  }

  .right ul {
    padding: 0 20px 20px;
  }
}
