.root {
  background-color: #000;
  padding: 200px 20px;
  display: flex;
  justify-content: space-around;
}

.title {
  margin-bottom: 20px;
  text-align: center;
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.icon {
  height: 30px;
}

.icon path,
.social path {
  fill: var(--accent-color);
}

.link {
  color: #fff;
  font-family: "Roboto Mono", Sans-serif;
  letter-spacing: 8px;
  font-size: 15px;
}

.link:hover {
  text-decoration: underline;
}

.social {
  height: 25px;
}

@media (max-width: 768px) {
  .root {
    flex-direction: column;
    gap: 50px;
    padding: 70px 20px;
  }
}
